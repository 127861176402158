<template>
    <div class="order">
        <div class="search">
            <div class="a-inline-block">
                <label for="">发票编号</label><a-input v-model="sn" size="large" placeholder="请输入发票编号" />
            </div>
            <div class="a-inline-block">
                <label for="">客户名称</label><a-input v-model="name" size="large" placeholder="请输入客户名称" />
            </div>
            <div class="a-inline-block">
                <label for="">发票性质</label>
                <a-select size="large" v-model="type">
                    <a-select-option :value="null">全部</a-select-option>
                    <a-select-option :value="1">专票</a-select-option>
                    <a-select-option :value="2">普票</a-select-option>
                    <a-select-option :value="3">收据</a-select-option>
                </a-select>
            </div>
            
            <div class="a-inline-block">
                <label for="">领取人</label><a-input v-model="staff_uid2" size="large" @click="chooseStaff" placeholder="请输入领取人名称" />
            </div>
            <staff :staffFlag="staffFlag"  @updateStaffFlag="updateStaffFlag"></staff>
            <div class="a-inline-block2">
                <label class="info" for="">领取日期</label>
                <a-range-picker v-model="date" size="large" />
            </div>
            <div class="a-inline-block">
                <label for="">发票状态</label>
                <a-select size="large" v-model="status">
                    <a-select-option :value="null">全部</a-select-option>
                    <a-select-option :value="1">生效</a-select-option>
                    <a-select-option :value="0">作废</a-select-option>
                </a-select>
            </div>
            <div class="a-inline-block2">
                <label class="info" for="">开票日期</label>
                <a-range-picker v-model="date2" size="large" />
            </div>
            <div class="a-inline-block">
                <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                <a-button size="large" @click="reset">重置</a-button>
            </div>

            <div style="text-align:right;">
                <a-button style="width:auto;" size="large" type="primary" @click="delAll">批量删除</a-button>
            </div>
            
        </div>
        <a-table :row-selection="rowSelection" :rowKey='record=>record.id' :columns="columns" :data-source="data" class="table" id="myTable" :pagination="pagination">
            <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Action" slot-scope="text,record">
                <a class="action" href="javascript:;" v-if="record.status != '作废'" @click="editInvoice(record.id)">编辑</a>
                
                <a-popconfirm title="确定要作废吗?" v-if="record.status != '作废'" @confirm="() => off(record.id)">
                    <a class="action del" href="javascript:;">作废</a>
                </a-popconfirm>
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
                    <a class="action del" href="javascript:;">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
    </div>
</template>
<script>
import $ from "jquery";
import {requestXml,getDateTime} from '../../../assets/js/request';
// var moment = require('moment');
import staff from '../../../components/staff.vue';
export default {
    components: { staff },
    data() {
        return {
            // 发票编号
            sn: "",
            // 客户名称
            name: "",
            // 发票性质
            type: null,

            // 开票日期
            date: [],
            // 开始时间
            start: "",
            // 结束时间
            end: "",

            // 领取日期
            date2: [],
            // 开始时间
            start2: "",
            // 结束时间
            end2: "",

            status: null,
            staff_uid: "",
            staff_uid2: "",
            staffFlag: false,
            columns:[
                { title: '序号', dataIndex: 'num', width: 70, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                { title: '发票编号', dataIndex: 'sn',width: 100},
                { title: '发票状态', dataIndex: 'status',width: 100 },
                { title: '客户名称', dataIndex: 'name',width: 100},
                { title: '发票性质', dataIndex: 'type',width: 100 },
                { title: '开票金额', dataIndex: 'amount',width: 100 },
                { title: '开票日期', dataIndex: 'sign_time',width: 150 },
                { title: '领取人', dataIndex: 'staff_name',width: 100  },
                { title: '领取日期', dataIndex: 'creater_time',width: 150  },
                { title: '备注', dataIndex: 'doc',width: 150  },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 350,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            data:[],
            selectedRows:[],
            rowSelection:{
                    onSelect: (record, selected, selectedRows) => {
                        console.log(record, selected, selectedRows);
                        this.selectedRows=selectedRows;
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        console.log(selected, selectedRows, changeRows);
                        this.selectedRows=selectedRows;
                    },
                },

            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
			},
            selectedRowKeys: []
        };
    },
    mounted: function(){
        this.getInvoice();
    },
    methods: {
        // 领取人
        chooseStaff() {
            this.staffFlag = true;
        },
        // 分配完 取消表格选中 清空客户 重新加载列表
        updateStaffFlag(data){
            if(data != 1){
                this.staff_uid = data.userid;
                this.staff_uid2 = data.name;
            }
            this.staffFlag = false;
        },
        // 搜索
        onSearch(){
            this.pagination.current=1;
            console.log(this.date)
            if(this.date.length != 0){
                this.date[0]._d.setHours(0);
                this.date[0]._d.setMinutes(0);
                this.date[0]._d.setSeconds(0);

                this.date[1]._d.setHours(23);
                this.date[1]._d.setMinutes(59);
                this.date[1]._d.setSeconds(59);
                
                this.start = this.date[0]._d.getTime();
                this.end = this.date[1]._d.getTime();
            }else{
                this.start = "";
                this.end = "";
            }
            if(this.date2.length != 0){
                this.date2[0]._d.setHours(0);
                this.date2[0]._d.setMinutes(0);
                this.date2[0]._d.setSeconds(0);

                this.date2[1]._d.setHours(23);
                this.date2[1]._d.setMinutes(59);
                this.date2[1]._d.setSeconds(59);
                console.log(this.date)
                this.start2 = this.date2[0]._d.getTime();
                this.end2 = this.date2[1]._d.getTime();
            }else{
                this.start2 = "";
                this.end2 = "";
            }
            this.getInvoice();
        },
        reset(){
            this.sn = "";
            this.name = "";
            this.type= null;

            this.date = [];
            this.start = "";
            this.end = "";

            this.date2 = [];
            this.start2 = "";
            this.end2 = "";
            this.status = null;
            this.staff_id = "";
            this.getInvoice();
        },
        getInvoice() {
            requestXml("/scrm/Invoice/getAllList","POST",(res) => {
                console.log(res,"resres")
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sign_time = getDateTime(res.list[i].sign_time);
                    res.list[i].creater_time = getDateTime(res.list[i].creater_time);

                    if(res.list[i].type == 1){
                        res.list[i].type = "普票"
                    }else if(res.list[i].type == 2){
                        res.list[i].type = "专票"
                    }else{
                        res.list[i].type = "收据"
                    }

                    if(res.list[i].status == 1){
                        res.list[i].status = "生效"
                    }else{
                        res.list[i].status = "作废"
                    }
                    
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;

                var tr = document.createElement("tr");
                tr.setAttribute("id","mytr");
                tr.innerHTML = `<td style="width:70px;">本页合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>`+ res.sum.page.amount +`</td>`;
               
                var tr2 = document.createElement("tr");
                tr2.setAttribute("id","mytr2");
                tr2.innerHTML = `<td>全部合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>`+ res.sum.all.amount +`</td>`;

                // if($("#mytr").length > 0) {
                    $("#mytr").remove();
                    $("#mytr2").remove();
                // }else{
                    this.$nextTick(
                        function(){
                            $("#myTable").find(".ant-table-body").find("table").find("tbody").append(tr);
                            $("#myTable").find(".ant-table-body").find("table").find("tbody").append(tr2);
                        }
                    )
                // }
            },{ 
                "sn": this.sn,
                "ids": this.name,
                "type": this.type,
                "status": this.status,
                "creater_time_start": this.start,
                "creater_time_end": this.end,

                "sign_time_start": this.start2,
                "sign_time_end": this.end2,
                "staff_uid":this.staff_uid,
                "page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}
            })
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            console.log(current)
            this.pagination.current = current;
            this.getInvoice(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize)
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getInvoice(); //向后端发送请求
        },
        // 编辑
        editInvoice(id){
            this.$router.push({
                path: "/scrm_pc/order/invoiceAdd",
                query: {
                    id: id,
                }
            });
        },
        // 作废
        off(id){
            requestXml("/scrm/Invoice/setClose","POST",(res) => {
                console.log(res)
                this.getInvoice();
            },{"id": id})
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Invoice/del","POST",(res) => {
                console.log(res)
                this.getInvoice();
            },{"id": id})
        },
        delAll(){
               if(this.selectedRows.length<1){
                this.$message.info('请先选择');
                return false;
            }else{
                let idArr=[];
                let sel=this.selectedRows;
                for(let i=0;i<sel.length;i++){
                    idArr.push(sel[i].id);
                }
                this.onDelete(idArr);
            }
        },
    },
    
};
</script>
<style scoped>
    .order .search .ant-calendar-picker{
        width: 300px !important;
    }
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
</style>

